import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PlatformLocation } from '@angular/common';
import { Observable } from 'rxjs';
import { Team } from '../../shared/models/team.model';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private http: HttpClient, private pl: PlatformLocation) {}

  // registerUser(user: User): Observable<Invitation> {
  //   const url = environment.apiResourceUri + '/users' + '/register';

  //   return this.http.post<Invitation>(url, user);
  // }

  getLeaderboard(): Observable<Team[]> {
    const url = 'https://pa.readerbench.com/api/leaderboard';

    return this.http.get<Team[]>(url);
  }

  getReplay(replay: string): Observable<any> {
    const url = 'https://pa.readerbench.com/replay/' + replay;

    return this.http.get<any>(url);
  }

  // getEventsWithParams(
  //   paramsMap: Map<string, object>,
  //   page: Pageable
  // ): Observable<IPagedResponse<Event>> {
  //   const url = environment.apiResourceUri + '/events';

  //   let params = new HttpParams();
  //   params = page.createPageableParams(params);
  //   paramsMap.forEach(
  //     (value, key) => (params = params.set(key, value.toString()))
  //   );

  //   return this.http.get<IPagedResponse<Event>>(url, { params });
  // }
}
