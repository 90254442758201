import { NgModule } from '@angular/core';
import { TemplateComponent } from './template.component';

import { TemplateRouting } from './template.routing';
import { SharedModule } from '../../shared/shared.module';
import { TeamReplaysDialogComponent } from './team-replays-dialog/team-replays-dialog.component';

@NgModule({
  declarations: [TemplateComponent, TeamReplaysDialogComponent],
  imports: [
    SharedModule,
    TemplateRouting,
  ],

})
export class TemplateModule {}
