import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Team } from '../../../shared/models/team.model';
import { CommonService } from '../../../core/http/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Replay } from '../../../shared/models/replay.model';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-team-replays-dialog',
  templateUrl: './team-replays-dialog.component.html',
  styleUrls: [
    './team-replays-dialog.component.scss',
    '../../../shared/style/responsive-table.component.scss',
  ],
})
export class TeamReplaysDialogComponent implements OnInit, AfterViewInit {
  replays: Team[];
  name: string;
  dataSource: MatTableDataSource<Replay>;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = [
    'team1',
    'team2',
    'team3',
    'team4',
    'size',
    'winnerBool',
    'ts',
    'download',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: CommonService,
    public dialogRef: MatDialogRef<TeamReplaysDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.data = this.data.replays;
    this.name = this.data.name;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  downloadReplay(row: Replay): void {
    this.service.getReplay(row.path.split('/')[2]).subscribe((response) => {
      const blob = new Blob([JSON.stringify(response)], { type: 'text/json' });
      FileSaver.saveAs(blob, row.path.split('/')[2]);
    });
  }

  exit(): void {
    this.dialogRef.close();
  }
}
