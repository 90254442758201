<div class="wrapper" fxLayout="column" fxLayoutAlign="start center">
  <h3 fxFlexOffset="1%">Team {{ name }} history</h3>
  <div class="table-wrapper" fxFlexOffset="1%" fxLayout="row" fxLayoutAlign="center center">
    <mat-table
      fxFlex="100%"
      fxFlex.lt-md="100%"
      [dataSource]="dataSource"
      class="table-responsive"
      matSort
    >
      <ng-container matColumnDef="team1">
        <mat-header-cell *matHeaderCellDef> Team 1 </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Team 1">
          {{ row.teams[0] }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="team2">
        <mat-header-cell *matHeaderCellDef> Team 2 </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Team 2">
          {{ row.teams[1] }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="team3">
        <mat-header-cell *matHeaderCellDef> Team 3 </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Team 3">
          {{ row.teams[2] }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="team4">
        <mat-header-cell *matHeaderCellDef> Team 4 </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Team 4">
          {{ row.teams[3] }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="size">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Dimension </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Dimension">
          {{ row.size }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="winnerBool">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Winner </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Winner">
          <mat-icon *ngIf="row.winnerBool">emoji_events</mat-icon>
          <mat-icon *ngIf="!row.winnerBool">close</mat-icon>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ts">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Seed </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Seed">
          {{ row.ts }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="download">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Replay </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Replay">
          <button mat-icon-button (click)="downloadReplay(row)"><mat-icon>download</mat-icon></button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <button fxFlexOffset="1%" mat-raised-button color="warn" (click)="exit()">Close</button>
</div>
