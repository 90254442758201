<div class="wrapper" fxLayout="column" fxLayoutAlign="start center">
  <h3 fxFlexOffset="1%">Leaderboard</h3>
  <div class="table-wrapper" fxFlexOffset="1%" fxLayout="row" fxLayoutAlign="center center">
    <mat-table
      fxFlex="80%"
      fxFlex.lt-md="100%"
      [dataSource]="dataSource"
      class="table-responsive"
      matSort
    >
      <ng-container matColumnDef="rank">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Rank </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Rank">
          {{ row.rank }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Name">
          {{ row.name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="lang">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Language </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Language">
          {{ row.lang }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="skill">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Skill </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Skill">
          {{ row.skill | number: '0.0-2' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="single_player_score">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Single Player Score </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Single Player Score">
          {{ row.single_player_score }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ts">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Submission Date </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Submission Date">
          {{ row.ts }}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="openDetails(row)" class="clickable"></mat-row>
    </mat-table>
  </div>
</div>
