import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Team } from '../../shared/models/team.model';
import { CommonService } from '../../core/http/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { TeamReplaysDialogComponent } from './team-replays-dialog/team-replays-dialog.component';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: [
    './template.component.scss',
    '../../shared/style/responsive-table.component.scss',
  ],
})
export class TemplateComponent implements OnInit {
  form: FormGroup;
  userRegistered: Boolean;
  invitationUrl: string;
  teams: Team[];
  dataSource: MatTableDataSource<Team>;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['rank', 'name', 'lang', 'skill', 'single_player_score', 'ts'];

  constructor(private service: CommonService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.service.getLeaderboard().subscribe(
      (teams) => {
        this.teams = teams;
        console.log(this.teams);
        this.dataSource.data = this.teams;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        console.error(
          'There was an error accessing the web service. Please try again or contact the PA Project Team'
        );
        console.error(error);
      }
    );
  }

  openDetails(row: Team) {
    if (row.replays && row.replays.length && !row.replays[0].teams) {
      row.replays.forEach(replay => {
        replay.teams = [];
        const teamIds = replay.ids.split('-');
        teamIds.forEach((id) => {
          replay.teams.push(this.teams.filter((team) => team.idx === Number(id))[0].name);
        });
        replay.winnerBool = Number(replay.winner) === row.idx;
      });
      console.log(row);
    }

    this.dialog.open(TeamReplaysDialogComponent, {
      maxWidth: '95%',
      data: {
        name: row.name,
        replays: row.replays
      }
    });
  }
}
